<div class="pl-pane__container--horizontal">
  <div class="pl-pane">
    <section>
      <se-fe-empty-state
        [seFeDataTitle]="title | translate">
        <se-fe-icon seFeEmptyStateIcon seFeDataName="whistle_thin" seFeDataSize="800" seFeDataColor="gray-900"></se-fe-icon>
        <div se-cp-empty-state-description>{{ errorMessage | translate }}</div>
      </se-fe-empty-state>
   </section>
  </div>

  <footer class="pl-page-footer pl-pane--shrink">
    <ul class="pl-page-footer__items">
      <li class="pl-page-footer__item">
        &copy;{{ year }}
        <a class="pl-link" href="https://www.sportsengine.com" target="_blank">SportsEngine</a>.
        <span>{{ 'LEGAL.all_rights_reserved' | translate }}</span>
      </li>
      <li class="pl-page-footer__item">
        <a class="pl-link" href="https://www.sportsengine.com/legal/terms_of_use" target="_blank">{{ 'LEGAL.terms_of_use' | translate }}</a>
      </li>
      <li class="pl-page-footer__item">
        <a class="pl-link" href="https://www.sportsengine.com/legal/privacy_policy" target="_blank">{{ 'LEGAL.privacy_policy' | translate}}</a>
      </li>
      <li class="pl-page-footer__item">
        <a class="pl-link" href="https://help.sportsengine.com/" target="_blank">{{ 'LEGAL.help' | translate }}</a>
      </li>
    </ul>
  </footer>
</div>
