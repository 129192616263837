import { Component } from '@angular/core';

@Component({
  selector: 'se-public-api-root',
  template: `
    <router-outlet></router-outlet>
    <se-fe-toast></se-fe-toast>
    <se-fe-confirm></se-fe-confirm>
  `
})
export class AppComponent {
}
