import { Inject, Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Tenant } from '../models/tenant';
import { TENANT_KEY } from '../services/tenant-key.provider';

@Injectable()
export class TenantKeyGuard implements CanActivate {

  constructor(
    private router: Router,
    @Inject(TENANT_KEY) private tenantKey: string,
  ) {}

  public canActivate(): boolean | UrlTree {
    return Tenant.isValidKey(this.tenantKey) || this.router.parseUrl('error/invalid-tenant-key');
  }

}
