import { Route } from '@angular/router';
import { SeAuthGuardService } from 'se-fe-auth-support';
import { SeFeFrameRouterService } from 'se-fe-frame-router';
import { TenantKeyGuard } from './guards/tenant-key.guard';
import { AppErrorComponent } from './app-error/app-error.component';
import { ShellComponent } from './shell/shell.component';
import { ConnectedAppsResolver } from './resolvers/connected-apps.resolver';
import { FeatureToggleService } from './services/feature-toggle.service';
import { OrganizationResolver } from './resolvers/oganization.resolver';


export const appRoutes: Route[] = [
  {
    path: 'error/:errorKey',
    component: AppErrorComponent,
    // resolve: { TranslationsReadyErrorsResolver },
  },
  {
    path: '',
    component: ShellComponent,
    canActivate: [SeAuthGuardService, SeFeFrameRouterService, TenantKeyGuard],
    children: [
      {
        path: '**',
        redirectTo: 'connections',
      },
      {
        path: 'connections',
        resolve: {
          connectedApps: ConnectedAppsResolver,
          productActive: FeatureToggleService,
          organization: OrganizationResolver,
        },
        loadChildren: () => import('./connections/connections.module').then(m => m.ConnectionsModule),
      }
    ],
  },
  { path: '**', redirectTo: 'error/not-found' },
];
