import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SeFeApiModule } from 'se-fe-api';
import { SeFeAuthSupportModule } from 'se-fe-auth-support';
import { SeFeCardModule } from 'se-fe-card';
import { SeFeConfirmModule } from 'se-fe-confirm';
import { SeFeEmptyStateModule } from 'se-fe-empty-state';
import { SeFeFrameRouterModule } from 'se-fe-frame-router';
import { SeFeHeaderModule } from 'se-fe-header';
import { SeFeIconModule, seFeIconWhistleThin } from 'se-fe-icon';
import { SeFePageModule } from 'se-fe-page';
import { SeFeTabGroupModule, SeFeTabsModule } from 'se-fe-tabs';
import { SeFeToastModule } from 'se-fe-toast';
import { environment } from '../environments/environment';
import { AppErrorComponent } from './app-error/app-error.component';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routes';
import { TenantKeyGuard } from './guards/tenant-key.guard';
import { baseHrefProvider } from './services/base-href.provider';
import { ShellComponent } from './shell/shell.component';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/');
}

@NgModule({
  declarations: [AppComponent, ShellComponent, AppErrorComponent],
  imports: [
    BrowserAnimationsModule,

    SeFeHeaderModule,
    SeFeCardModule,
    SeFeConfirmModule,
    SeFePageModule,
    SeFeTabsModule,
    SeFeTabGroupModule,
    SeFeEmptyStateModule,
    SeFeIconModule.include([seFeIconWhistleThin]),
    BrowserModule,

    HttpClientModule,

    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload', // allow routes to reload components
      paramsInheritanceStrategy: 'always', // all routes get all params
      urlUpdateStrategy: 'eager', // replace url on redirect from guard
    }),

    SeFeApiModule.forRoot({ domainEnvironments: environment.domainEnvironments }),

    SeFeFrameRouterModule.forRoot({
      origin: environment.frameRouterOrigin,
    }),
    SeFeAuthSupportModule.forRoot(environment),


    SeFeToastModule,

    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),

    FormsModule,
    ReactiveFormsModule,
  ],
  providers: [
    TenantKeyGuard,
    baseHrefProvider,
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
