export const environment = {
  frameRouterOrigin: ['https://app.stage.ngin-staging.com', 'https://org.ui.stage.ngin-staging.com', 'https://org.stage.ngin-staging.com'],
  domainEnvironments: [
    {
      domain: 'stage.ngin-staging.com',
      default: true,
      urls: {
        api: 'https://api.stage.ngin-staging.com',
        user: 'https://user.stage.ngin-staging.com/',
      }
    },
    {
      domain: 'sestage.us',
      default: false,
      urls: {
        api: 'https://se-api.sestage.us',
        user: 'https://user.sestage.us/',
      }
    }
  ],
  domainSpecificUrls: [
    {
      domain: 'stage.ngin-staging.com',
      default: true,
      api: 'https://api.stage.ngin-staging.com',
      user: 'https://user.stage.ngin-staging.com/',
    },
    {
      domain: 'sestage.us',
      default: false,
      api: 'https://se-api.sestage.us',
      user: 'https://user.sestage.us/',
    }
  ],
  productKey: 'api' // TODO: update to the final name of the feature toggle
}
