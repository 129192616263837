import { Inject, Injectable } from "@angular/core";
import { firstValueFrom, map } from "rxjs";
import { SeFeApiService } from "se-fe-api";
import { Tenant } from "../models/tenant";
import { TENANT_KEY } from "./tenant-key.provider";
import { Organization } from "../models/organization";

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  private tenant: Tenant;
  private promise?: Promise<Organization>;
  
  constructor(
    private api: SeFeApiService,
    @Inject(TENANT_KEY) tenantKey: string,
  ) {
    this.tenant = new Tenant(tenantKey);
  }

  getOrganization() {
    if (!this.promise) {
      this.promise = firstValueFrom(
        this.api.get<Organization>(`organizations/${this.tenant.id}`)
          .pipe(
            map(result => {
              return result.result;
            })
          )
      );
    }
    return this.promise;
  }
}
