import {firstValueFrom, map} from 'rxjs';
import { SeFeApiService } from 'se-fe-api';
import { Inject, Injectable } from "@angular/core";
import { ConnectedApp } from "../models/connected-apps";
import { TENANT_KEY } from "./tenant-key.provider";
import { Tenant } from "../models/tenant";
import { OrganizationService } from './organization.service';

@Injectable({
  providedIn: 'root',
})
export class ConnectedAppsService {
  private readonly tenant: Tenant;

  constructor(
    private api: SeFeApiService,
    @Inject(TENANT_KEY) tenantKey: string,
    private orgService: OrganizationService,
  ) {
    this.tenant = new Tenant(tenantKey);
  }

  async getConnectedApps(): Promise<ConnectedApp[]> {
    const org = await this.orgService.getOrganization();
    return firstValueFrom(
      this.api.get<ConnectedApp[]>(`/oauth/client_organizations?organization_id=${this.tenant.id}`)
        .pipe(
          map(res => {
            res.result = res.result
              // Not an NGIN app and not owned by the current org
              .filter(x => !x.client.ngin && x.client.owner_organization_id != org.id)
              .map(x => {
                return {
                  ...x,
                  _connectedOn: new Intl.DateTimeFormat('en', {
                    dateStyle: 'medium',
                    timeStyle: 'long',
                    timeZone: org.timezone,
                  }).format(new Date(x.created_at))
                }
              })
            return res.result;
          })
        )
    );
  }
}
