export const TENANT_KEY_PATH = ':tenantKey';

export class Tenant {
  public readonly id: string;
  public readonly type: string;

  public static isValidKey(key: string): boolean {
    return key?.includes(':');
  }

  public get valid(): boolean {
    return !!(this.id && this.type);
  }

  constructor(public readonly key: string) {
    if (Tenant.isValidKey(key)) {
      const tenantElements = this.key.split(':');
      this.id = tenantElements[1];
      this.type = tenantElements[0];
    } else {
      throw 'not-valid-tenant-key';
    }
  }
}
