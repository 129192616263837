import { Injectable, inject } from '@angular/core';
import { Resolve } from '@angular/router';
import { ConnectedAppsService } from '../services/connected-apps.service';
import { ConnectedApp } from '../models/connected-apps';

@Injectable({
  providedIn: 'root'
})
export class ConnectedAppsResolver implements Resolve<Promise<ConnectedApp[]>> {
  private connectedApps = inject(ConnectedAppsService);

  resolve(): Promise<ConnectedApp[]> {
    return this.connectedApps.getConnectedApps()
  }
}
