import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'se-public-api-app-error',
  templateUrl: './app-error.component.html',
  styleUrls: ['./app-error.component.css']
})
export class AppErrorComponent {
  public year = new Date().getFullYear();
  public readonly errorKey: string;

  constructor(route: ActivatedRoute) {
    this.errorKey = route.snapshot.params['errorKey']?.replace(/-/g, '_');
  }

  public get title(): string {
    return `APP_ERROR.${ this.errorKey }.title`;
  }

  public get errorMessage(): string {
    return `APP_ERROR.${ this.errorKey }.message`;
  }
}
