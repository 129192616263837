import { Injectable, inject } from '@angular/core';
import { Resolve } from '@angular/router';
import { OrganizationService } from '../services/organization.service';
import { Organization } from '../models/organization';

@Injectable({
  providedIn: 'root'
})
export class OrganizationResolver implements Resolve<Promise<Organization>> {
  private orgService = inject(OrganizationService);

  resolve() {
    return this.orgService.getOrganization();
  }
}
