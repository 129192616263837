import { SeFeApiService } from 'se-fe-api';
import { Inject, Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { environment } from '../../environments/environment';
import { TENANT_KEY } from './tenant-key.provider';

export interface OrganizationProduct {
  product_key: string;
  status: string;
}

@Injectable({
  providedIn: 'root',
})
export class FeatureToggleService implements Resolve<Promise<boolean>> {

  constructor(
    @Inject(TENANT_KEY) private tenantKey: string,
    private api: SeFeApiService,
  ) {
  }

  public resolve(): Promise<boolean> {
    return this.isProductEnabled(environment.productKey);
  }

  public async isProductEnabled(productKey: string): Promise<boolean> {
    const url = `/organization_products`;
    const params = { organization_id: this.tenantKey.split(':')[1] };
    const { result } = await firstValueFrom(this.api.get<OrganizationProduct[]>(url, { params }));

    const product = result.find(product => product.product_key == productKey);
    return product?.status == 'active';
  }

}
