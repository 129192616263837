<se-fe-page [seFeDataLoaded]="true" SeFeDataWidth="full">

  <se-fe-header seFePageHeader
                [seFeDataTitle]="title"
                seFeDataSize="750">
  </se-fe-header>

  <div seFePageTabs> 
    <se-fe-tab-group>

      <se-fe-tab-item>
        <a [seFeTab]="true">Connections</a>
      </se-fe-tab-item>
  
    </se-fe-tab-group>
  </div>

  <div seFePageContent>
    <router-outlet></router-outlet>
  </div>

</se-fe-page>
